import axios from "axios";

export const backendIP = process.env.REACT_APP_PUBLIC_API_URL;
export async function ApiCall({
  api,
  map = {},
  post = false,
  put = false,
  del = false,
  params = {},
  isFile = false,
  exception = false,
  dontLog = false,
}) {
  const headers = {
    "Content-Type": isFile ? "multipart/form-data" : "application/json",
    "x-access-token": localStorage.getItem("token"),
  };
  const url = `${backendIP}/${api}`;
  try {
    const response = post
      ? await axios.post(url, map, {
          headers: headers,
          params: params,
        })
      : put
      ? await axios.put(url, map, {
          headers: headers,
          params: params,
        })
      : del
      ? await axios.delete(url, {
          headers: headers,
          params: params,
          data: map,
        })
      : await axios.get(
          url,
          {
            headers: headers,
            params: params,
          },
          map
        );
    if (response.status === 401 && !exception) {
      return { success: false };
    }
    return response.data;
  } catch (error) {
    if (!dontLog) console.log(error);
    if (error.response && error.response.status === 401 && !exception) {
    }
    return error.response
      ? error.response.data
        ? error.response.data
        : { success: false }
      : { success: false };
  }
}
