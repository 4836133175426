import React from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title:'Hollo AI',
      subtitle:'SEO Content, Social Media Marketing',
      src:'/images/digital marketing/projects/Hollo Cover Image.webp'
    },
    {
      title:'Residents',
      subtitle:'Social Media Marketing',
      src:'/images/digital marketing/projects/Residents Cover Image.webp'
    },
    {
      title:'Creator Space',
      subtitle:' Google ads, Meta ads, Social Media Marketing, Email and SMS Marketing, SEO Content',
      src:'/images/digital marketing/projects/Creator Space Cover Image.webp'
    },
    {
      title:'Lovers Los Angeles',
      subtitle:'Meta Ads,Tiktok Ads,Google Ads,Social Media Marketing,Email Marketing',
      src:'/images/digital marketing/projects/Lover LA Cover Image.webp'
    },
    {
      title:'Boutique Beyrouth',
      subtitle:'Email and SMS Marketing, Social Media Marketing',
      src:'/images/digital marketing/projects/Boutique Beyrouth Cover Image.webp'
    },
    {
      title: 'Grade Hero',
      subtitle: 'SEO Content, Social Media Marketing',
      src: '/images/digital marketing/projects/Grade Hero Cover Image.webp'
    },
    {
      title: 'Le Bowl',
      subtitle: 'SEO Content, Email Marketing',
      src:'/images/digital marketing/projects/Le Bowl Cover Image.webp'
    }
  ]
  
  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  
  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {portfolioData.map((item, index)=> (
        <Div key={index} >
          <Portfolio 
            title={item.title} 
            subtitle={item.subtitle}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  )
}
