import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
// import Cta from '../Cta'
// import PageHeading from '../PageHeading'
// import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
// import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import VerticalLinks from '../VerticalLinks'
import { useLocation } from 'react-router-dom'
export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation();
  const heroSocialLinks = [
    {
      name: 'Services',
      links: '/service',
      class: location.pathname === '/service' ? 'Active' : '',
    },
    {
      name: '|'
      
    },
    {
      name: 'Projects',
      links: '/digital-marketing-projects',
      class: location.pathname === '/digital-marketing-projects' ? 'Active' : '',
    },
  ];
  return (
    <>
      
      <Spacing lg='150' md='80'/>
      <Spacing lg='20' md='50'/>
    
      <Div className='d-flex container flex-column align-items-start align-self-center'>
      <VerticalLinks
          data={heroSocialLinks}
          className='cs-shape_4'
          variant="cs-left_side"
        />
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Digital Marketing services we offer' 
                subtitle='What Can We Do'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Facebook Ads'
                    src='/images/services/facebook.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='TikTok Ads'
                    src='/images/services/tiktok.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='SEO Content'
                    src='/images/service_1.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Google Ads'
                    link='/service/technology'
                    src='/images/services/google.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Influencer Marketing'
                    src='/images/services/influencer.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='SMS/Email Marketing'
                    src='/images/services/email.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
               
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                {/* <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Analytics and Data Analysis'
                    link='/service/technology'
                    src='/images/service_2.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div> */}
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
    
      {/* <Div className="container">
        <SectionHeading
          title='Providing best <br/>pricing for client' 
          subtitle='Pricing & Packaging'
        />
        <Spacing lg='85' md='40'/>
        <PricingTableList/>
      </Div> */}
      {/* <Spacing lg='125' md='55'/>
      <TestimonialSlider/> */}
      {/* <Spacing lg='150' md='80'/> */}
      {/* <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='contact us now!' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div> */}
    </>
  )
}
