import { Route, Routes } from "react-router-dom";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import MarketingAgencyHome from "./components/Pages/MarketingAgencyHome";
import DigitalAgencyHome from "./components/Pages/DigitalAgencyHome";
import Layout from "./components/Layout";
import DigitalMarketingProjects from "./components/Pages/DigitalMarketingProjects";
import WebDevelopmentServicesPage from "./components/Pages/WebDevelopmentServices";
import { useState } from "react";

function App() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Layout footer={false} open={open} setOpen={setOpen} />}>
          <Route index element={<Home setOpen={setOpen} open={open} />} />
          <Route
            path="marketing-agency"
            element={<MarketingAgencyHome setOpen={setOpen} open={open} />}
          />
          <Route
            path="digital-agency"
            element={<DigitalAgencyHome setOpen={setOpen} open={open} />}
          />
          <Route
            path="digital-marketing-projects"
            element={<DigitalMarketingProjects />}
          />
          <Route
            path="web-development-services"
            element={<WebDevelopmentServicesPage />}
          />
          <Route path="service" element={<ServicesPage />} />

          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="projects" element={<PortfolioPage />} />
          <Route
            path="projects/:projectsDetailsId"
            element={<PortfolioDetailsPage />}
          />
        </Route>
        {/* <Route
            path="photography-agency"
            element={<PhotographyAgencyHome />}
          /> */}
        {/* <Route path="about" element={<AboutPage />} /> */}
        <Route path="/" element={<Layout footer={true} />}>
          {/* <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} /> */}
          <Route path="contact" element={<ContactPage />} />
          {/* <Route path="team" element={<TeamPage />} /> */}
          {/* <Route path="team/:teamDetails" element={<TeamDetails />} />
          <Route
            path="/case-study/:caseStudyDetailsId"
            element={<CaseStudyDetailsPage />}
          />
          <Route path="faq" element={<FaqPage />} /> */}
        </Route>
        {/* <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          <Route
            path="creative-portfolio"
            element={<CreativePortfolioHome />}
          />
          <Route
            path="showcase-portfolio"
            element={<ShowcasePortfolioHome />}
          />
          <Route
            path="case-study-showcase"
            element={<CaseStudyShowcaseHome />}
          />
        </Route> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
