import React from 'react'
import Div from '../Div'

export default function TextWidget({logoSrc, logoAlt, text}) {
  return (
    <Div className="cs-text_widget" style={{width:"170px" ,marginTop:"-35px"}}>
      <img src={logoSrc} alt={logoAlt} />
      <p style={{marginTop:"-1px"}}>{text}</p>
    </Div>
  )
}
