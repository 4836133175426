import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { useFormik } from "formik";
import * as Yup from "yup";
import { createContact } from '../../services/contact';

export default function ContactPage() {
  const [successMessage, setSuccessMessage] = useState("")
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      project: "",
      mobile: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required")
      .test(
        "valid-tld",
        "Email must have a valid top-level domain",
        (value) => {
          const tld = !!value && value.split(".").pop();
          if (tld) return tld.length >= 2;
          else return true;
        }
      )
      .trim(),
      project: Yup.string().required("Project Type is Required"),
      mobile: Yup.string().matches(/^[0-9+/-]*$/, "Invalid characters in the mobile number").required("Mobile is Required"),
      message: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        
        const res = await createContact(values);
        if (res.state) {
          formik.resetForm();
          setSuccessMessage("Thanks for contacting us!");
          // Hide the success message after 3 seconds
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        }
      } catch (err) {
        console.log("nope", err)
      }
    }
  })
 
  
   
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form  onSubmit={formik.handleSubmit} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" className="cs-form_field" name="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} />
                {formik.touched.name && formik.errors.name ? (
          <p style={{color:"red"}}>{formik.errors.name}</p>
                ) : null}
                
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="text" className="cs-form_field"  name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                {formik.touched.email && formik.errors.email ? (
          <p style={{color:"red"}}>{formik.errors.email}</p>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" className="cs-form_field" name="project" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.project} />
                {formik.touched.project && formik.errors.project ? (
          <p style={{color:"red"}}>{formik.errors.project}</p>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" className="cs-form_field" name="mobile" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobile} />
                {formik.touched.mobile && formik.errors.mobile ? (
          <p style={{color:"red"}}>{formik.errors.mobile}</p>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  name="message" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.message} 
                ></textarea>
                {formik.touched.message && formik.errors.message ? (
          <p style={{color:"red"}}>{formik.errors.message}</p>
                ) : null}
                <Spacing lg="25" md="25" />
              </Div>
              <h3>{successMessage}</h3>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" type="submit">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" /> */}
    </>
  );
}
