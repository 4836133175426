import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Div from "../Div";
import ContactDialog from "../Contactdialog";
import DropDown from "./DropDown";

export default function Header({ variant, open, setOpen }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);
  // Create a new Date object
  const currentDate = new Date();

  // Format the date as a string (e.g., "January 17, 2024")
  const formattedMonth = currentDate.toLocaleDateString("en-US", {
    month: "long",
  });
  const formattedYear = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
  });

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/skewLogo.png" alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    <li>
                      <NavLink to="/" onClick={() => setMobileToggle(false)}>
                        Home
                      </NavLink>
                    </li>
                    <li
                      className={`${
                        mobileToggle ? "menu-item-has-children" : ""
                      }`}
                    >
                      <NavLink
                        to="marketing-agency"
                        onClick={() => setMobileToggle(false)}
                      >
                        Digital Marketing
                      </NavLink>
                      <div
                        style={{
                          display: `${mobileToggle ? "block" : "none"}`,
                        }}
                      >
                        <DropDown>
                          <ul>
                            <li>
                              <Link
                                to="/service"
                                onClick={() => setMobileToggle(false)}
                              >
                                Services
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/digital-marketing-projects"
                                onClick={() => setMobileToggle(false)}
                              >
                                Projects
                              </Link>
                            </li>
                          </ul>
                        </DropDown>
                      </div>
                    </li>
                    <li
                      className={`${
                        mobileToggle ? "menu-item-has-children" : ""
                      }`}
                    >
                      <NavLink
                        to="digital-agency"
                        onClick={() => setMobileToggle(false)}
                      >
                        Software Development
                      </NavLink>
                      <div
                        style={{
                          display: `${mobileToggle ? "block" : "none"}`,
                        }}
                      >
                        <DropDown>
                          <ul>
                            <li>
                              <Link
                                to="/web-development-services"
                                onClick={() => setMobileToggle(false)}
                              >
                                Services
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/projects"
                                onClick={() => setMobileToggle(false)}
                              >
                                Projects
                              </Link>
                            </li>
                          </ul>
                        </DropDown>
                      </div>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox"></Div>
              </Div>
            </Div>
            <p className="slogan">Redefining Digital Possibilities</p>
            <Div className="date-style">
              {formattedMonth}&nbsp;<span>{formattedYear}</span>
            </Div>
          </Div>
        </Div>
      </header>
      <Div
        className={
          sideHeaderToggle ? "cs-side_header active" : "cs-side_header"
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Div className="cs-side_header_shape" />
          <Link className="cs-site_branding" to="/">
            <img src="/images/skewLogo.png" alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              Do you have a project in your <br /> mind? Keep connect us.
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget title="Contact Us" withIcon />
          </Div>
        </Div>
      </Div>
      <div style={{ zIndex: 1001, position: "absolute" }}>
        <ContactDialog open={open} setOpen={setOpen} />
      </div>
    </>
  );
}
