import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { pageTitle } from "../../helper";
// import Cta from '../Cta';
// import PageHeading from '../PageHeading';
import Portfolio from "../Portfolio";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { useLocation } from "react-router-dom";
import VerticalLinks from "../VerticalLinks";
export default function PortfolioPage() {
  pageTitle("Portfolio");
  const [active] = useState("all");
  const location = useLocation();
  const [itemShow, setItemShow] = useState(7);
  const saas = [
    {
      title: "Grade Hero",
      subtitle: "See Details",
      href: "/projects/project-details?id=0",
      src: "/images/projects/Grade Hero Cover image.webp",
      category: "web_design",
    },
    {
      title: "NuWorlds",
      subtitle: "See Details",
      href: "/projects/project-details?id=1",
      src: "/images/projects/Nuworlds Cover Image.webp",
      category: "web_design",
    },
    {
      title: "Residents",
      subtitle: "See Details",
      href: `/projects/project-details?id=2`,
      src: "/images/projects/residents/cover.webp",
      category: "mobile_apps",
      url: "Residents.ai",
    },
    {
      title: "Hollo AI",
      subtitle: "See Details",
      href: "/projects/project-details?id=3",
      src: "/images/projects/Hollo Cover Image.webp",
      category: "logo_design",
      url: "www.hollo.ai",
    },
  ];
  const BrickAndMortar = [
    {
      title: "Jonah's Kabob",
      subtitle: "See Details",
      href: "/projects/project-details?id=15",
      src: "/images/projects/Jonah Cover image.webp",
      category: "ui_ux_design",
      url: "https://jonahskabob.shop/",
    },
    {
      title: "We Pop Bottles LA",
      subtitle: "See Details",
      href: "/projects/project-details?id=16",
      src: "/images/projects/We Pop Bottles Cover Image.webp",
      url: "www.wePopbottles.org",
    },
    {
      title: "Operation Fitness",
      subtitle: "See Details",
      href: "/projects/project-details?id=17",
      src: "/images/projects/Operation Fitness Cover Image.webp",
      url: "www.miketorchia.com",
    },
    {
      title: "Creator Space",
      subtitle: "See Details",
      href: "/projects/project-details?id=18",
      src: "/images/projects/Creator Space Cover Image.webp",
      url: "www.Creatorspace.la",
    },
    {
      title: "le Bowl",
      subtitle: "See Details",
      href: "/projects/project-details?id=19",
      src: "/images/projects/LeBowl.webp",
      category: "web_design",
    },
  ];
  const eommerce = [
    {
      title: "Keep it Classy Nails",
      subtitle: "See Details",
      href: "/projects/project-details?id=4",
      src: "/images/projects/Keepitclassynails Cover Image.webp",
      url: "www.keepitclassynails.com",
    },
    {
      title: "Lover Los Angeles",
      subtitle: "See Details",
      href: "/projects/project-details?id=5",
      src: "/images/projects/Lover LA Cover image.webp",
      category: "web_design",
      url: "https://loverlosangeles.com/",
    },
    {
      title: "Boutique Beyrouth",
      subtitle: "See Details",
      href: "/projects/project-details?id=6",
      src: "/images/projects/BB Cover image.webp",
      category: "ui_ux_design",
    },
    {
      title: "Cool Kids Group",
      subtitle: "See Details",
      href: "/projects/project-details?id=7",
      src: "/images/projects/Cool Kids Group Cover Image.webp",
      url: "https://coolkids.group/",
    },
  ];
  const portfolio = [
    {
      title: "Ryaan Sayegh",
      subtitle: "See Details",
      href: "/projects/project-details?id=8",
      src: "/images/projects/Ryaan Sayegh Cover Image.webp",
      url: "RyaanSayegh.com",
    },
    {
      title: "Julian Georges",
      subtitle: "See Details",
      href: "/projects/project-details?id=9",
      src: "/images/projects/Julian Georges Cover Image.webp",
    },
    {
      title: "Bry Properties",
      subtitle: "See Details",
      href: "/projects/project-details?id=10",
      src: "/images/projects/Bry Properties Cover Image.webp",
      url: "www.bry.properties",
    },
  ];
  const government = [
    {
      title: "Call Trans",
      subtitle: "See Details",
      href: "/projects/project-details?id=11",
      src: "/images/projects/Call Trans Cover image.webp",
      category: "logo_design",
    },
    {
      title: "Smart City Signals",
      subtitle: "See Details",
      href: "/projects/project-details?id=12",
      src: "/images/projects/SCS Cover image.webp",
      category: "ui_ux_design",
      url: "https://user.smartcitysignals.com/",
    },
    {
      title: "NTCIP",
      subtitle: "See Details",
      href: "/projects/project-details?id=13",
      src: "/images/projects/NTCIP Cover image.webp",
      category: "web_design",
    },
    {
      title: "School Routes",
      subtitle: "See Details",
      href: "/projects/project-details?id=14",
      src: "/images/projects/School Routes Cover Image.webp",
      category: "logo_design",
      url: "https://www.schoolroutes.org/",
    },
  ];
  const organizational = [
    {
      title: "Techademics",
      subtitle: "See Details",
      href: "/projects/project-details?id=20",
      src: "/images/projects/Techademics Cover image.webp",
      category: "web_design",
    },
    {
      title: "Chinese Union",
      subtitle: "See Details",
      href: "/projects/project-details?id=21",
      src: "/images/projects/CU Cover image.webp",
      category: "web_design",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const heroSocialLinks = [
    {
      name: "Services",
      links: "/web-development-services",
      class: location.pathname === "/web-development-services" ? "Active" : "",
    },
    {
      name: "|",
    },
    {
      name: "Projects",
      links: "/projects",
      class: location.pathname === "/projects" ? "Active" : "",
    },
  ];
  return (
    <>
      <Spacing lg="145" md="80" />
      <Div className="container mt-5 d-flex flex-column mt-4 align-self-center">
        <VerticalLinks
          data={heroSocialLinks}
          className="cs-shape_4"
          variant="cs-left_side"
        />
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="SaaS Companies" subtitle="" />
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {saas.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {saas.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="E-Commerce" subtitle="" />
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {eommerce.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {eommerce.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Portfolio" subtitle="" />
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolio.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolio.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Government Tech" subtitle="" />
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {government.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {government.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Brick and Mortar" subtitle="" />
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {BrickAndMortar.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {BrickAndMortar.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      ƒ√
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Organizational-Focused" subtitle="" />
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {organizational.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {organizational.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
    </>
  );
}
