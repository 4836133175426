import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  // const copyrightLinks = [
  //   {
  //     title: 'Terms of Use',
  //     href: '/'
  //   },
  //   {
  //     title: 'Privacy Policy',
  //     href: '/'
  //   }
  // ]

  const serviceMenu = [
    {
      title: "Digital marketing",
      href: "/service",
    },
    {
      title: "Software Development",
      href: "/projects",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/skewLogo.png"
                  logoAlt="Logo"
                  text="A forward-thinking digital agency creating personalized strategies and unique web solutions for exceptional online success"
                />
                {/* <SocialWidget/> */}
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              {/* <Div className="cs-footer_item">
                <Newsletter 
                  title='Subscribe' 
                  subtitle='At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit.' 
                  placeholder='example@gmail.com'
                />
              </Div> */}
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        {/* <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2022 Laralink.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div> */}
      </Div>
    </footer>
  );
}
