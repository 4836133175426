import React from "react";
import parse from "html-react-parser";
import Button from "../Button";
import Div from "../Div";
// import VerticalLinks from '../VerticalLinks';
// import { Slider, styled } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import VerticalLinks from "../VerticalLinks";

export default function Hero5({
  title,
  subtitle,
  btnLink,
  btnText,
  socialLinksHeading,
  heroSocialLinks,
  open,
  setOpen,
}) {
  // const iOSBoxShadow =
  // "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

  // const IOSSlider = styled(Slider)(({ theme }) => ({
  //   color: theme.palette.mode === "dark" ? "#0a84ff" : "#FFFFFF80",
  //   height: 100,
  //   padding: "15px 0",
  //   "& .MuiSlider-thumb": {
  //     height: 20,
  //     width: 20,
  //     backgroundColor: "#02A9F7",
  //     boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
  //     "&:focus, &:hover, &.Mui-active": {
  //       boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
  //       // Reset on touch devices, it doesn't add specificity
  //       "@media (hover: none)": {
  //         boxShadow: iOSBoxShadow,
  //       },
  //     },
  //     "&:before": {
  //       boxShadow:
  //         "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
  //     },
  //   },
  //   "& .MuiSlider-valueLabel": {
  //     fontSize: 2,
  //     fontWeight: "normal",
  //     color: "red",
  //     top: -6,
  //     backgroundColor: "unset",
  //     "&::before": {
  //       display: "none",
  //     },
  //     "&.MuiSlider-valueLabelOpen": {
  //       color: "red",
  //     },
  //     "& *": {
  //       background: "transparent",
  //       color: theme.palette.mode === "dark" ? "#fff" : "#FFFFFF80",
  //     },
  //   },
  //   "& .MuiSlider-track": {
  //     border: "none",
  //     height: 5,
  //   },
  //   "& .MuiSlider-rail": {
  //     opacity: 0.5,
  //     boxShadow: "inset 0px 0px 4px -2px #000",
  //     backgroundColor: "white",
  //   },
  // }));
  // const marks = [
  //   { value: 100, label: "Main" },
  //   { value: 50, label: "Services" },
  //   { value: 0, label: "Projects" },
  // ];
  // const navigate = useNavigate();

  // const handleSliderChange = (event, newValue) => {
  //   // newValue will give you the value of the slider when it's changed
  //   // You can use this value to determine which page to route to
  //   if (newValue === 100) {
  //     // Route to the Main page
  //     navigate("/marketing-agency");
  //   } else if (newValue === 0) {
  //     // Route to the Projects page
  //     navigate("/digital-marketing-projects");
  //   } else if (newValue === 50) {
  //     // Route to the Services page
  //     navigate("/services");
  //   }
  // };
  return (
    <Div
      className="cs-hero cs-style3 cs-bg cs-fixed_bg cs-shape_wrap_1"
      id="home"
    >
      <img
        className="digital-big-ball"
        src="/images/home/Ellipse 8.webp"
        alt="blue ball"
      />
      <img
        className="digital-small-ball"
        src="/images/digital marketing/projects/Ellipse 9.webp"
        alt="blue ball"
      />
      <img
        className="digital-left-object"
        src="/images/digital marketing/projects/Group 2.webp"
        alt="digital-left-object"
      />
      <img
        className="digital-right-object"
        src="/images/digital marketing/projects/Group 3.webp"
        alt="blue ball"
      />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-circle_1" />
      <Div className="cs-circle_2" />

      <Div className="container d-flex align-self-center flex-column">
      <VerticalLinks
          data={heroSocialLinks}
          title={socialLinksHeading}
          className='cs-shape_4'
          variant="cs-left_side"
        />
        <Div className="cs-hero_text text-center">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <Div className="cs-hero_subtitle ">
            Striving for excellence: Our commitment to delivering tailored
            <br />
            solutions and unmatched quality, Today and Beyond
          </Div>
          {btnLink && btnText && <Button btnLink={btnLink} btnText={btnText} />}
        </Div>
      </Div>
      {/* <div className='slider'>
        <IOSSlider   onChange={handleSliderChange} defaultValue={30}  aria-label="ios slider" marks={marks} orientation="vertical"/>
      </div> */}
      {/* {heroSocialLinks  &&
        <VerticalLinks
          data={heroSocialLinks}
          title={socialLinksHeading}
          variant="cs-left_side"
        />} */}
      <div onClick={() => setOpen(!open)} className="cs-down_btn">
        .
      </div>
      <div className="helper" onClick={() => setOpen(!open)}>
        Contact Us
      </div>
    </Div>
  );
}
