import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Card from "../Card";
// import Cta from '../Cta'
// import PageHeading from '../PageHeading'
// import PricingTableList from '../PricingTable/PricingTableList'
import Div from "../Div";
import SectionHeading from "../SectionHeading";
// import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from "../Spacing";
import VerticalLinks from "../VerticalLinks";
import { useLocation } from "react-router-dom";
export default function WebDevelopmentServicesPage() {
  pageTitle("Service");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const location = useLocation()
  const heroSocialLinks = [
    {
      name: "Services",
      links: "/web-development-services",
      class: location.pathname === '/web-development-services' ? 'Active' : '',
    },
    {
      name:'|'
    }
    ,
    {
      name: "Projects",
      links: "/projects",
      class: location.pathname === '/projects' ? 'Active' : '',
    },
  ];
  return (
    <>
      {/* <PageHeading 
        title='Digital Marketing'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Digital Marketing'
      /> */}
      <Spacing lg="150" md="80" />
      <Spacing lg="20" md="50" />
    
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="d-flex container flex-column mt-4 align-items-start align-self-center">
        <VerticalLinks
          data={heroSocialLinks}
          className='cs-shape_4'
          variant="cs-left_side"
        />
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Software Development services we offer"
                subtitle="What Can We Do"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Software Development"
                    src="/images/web-development-services/Website Development.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Mobile development"
                    src="/images/web-development-services/Mobile Development.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="System development"
                    src="/images/web-development-services/Custom Software Solution.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Full stack development"
                    src="/images/web-development-services/Full Stack Development.webp"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                {/* <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Influencer Marketing'
                    src='/images/services/influencer.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='SMS/Email Marketing'
                    src='/images/services/email.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div> */}

                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                {/* <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Analytics and Data Analysis'
                    link='/service/technology'
                    src='/images/service_2.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div> */}
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
     

      {/* <Div className="container">
        <SectionHeading
          title='Providing best <br/>pricing for client' 
          subtitle='Pricing & Packaging'
        />
        <Spacing lg='85' md='40'/>
        <PricingTableList/>
      </Div> */}
      {/* <Spacing lg='125' md='55'/>
      <TestimonialSlider/> */}
      {/* <Spacing lg='150' md='80'/> */}
      {/* <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='contact us now!' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div> */}
    </>
  );
}
