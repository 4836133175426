import { ApiCall } from ".";

export async function createContact(data) {
  const response = await ApiCall({
    api: "/skew-contacts",
    post: true,
    map: {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      message: data.message,
      projectType: data.project,
    },
  });
  return {
    state: response.success,
    message: response.message,
  };
}
