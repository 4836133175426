import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
// import { pageTitle } from '../../helper';
import Div from "../Div";
// import PageHeading from '../PageHeading';
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import { useFormik } from "formik";                                                                                                                   
import * as Yup from "yup";
import { createContact } from "../../services/contact";
import './dialog.scss';

export default function ContactDialog({ open, setOpen }) {
  const [successMessage, setSuccessMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      project: "",
      mobile: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Name is Required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required")
        .test(
          "valid-tld",
          "Email must have a valid top-level domain",
          (value) => {
            const tld = !!value && value.split(".").pop();
            if (tld) return tld.length >= 2;
            else return true;
          }
        )
        .trim(),
      project: Yup.string().trim().required("Project Type is Required"),
      mobileSchema : Yup.string()
  .matches(/^[0-9+\s/-]*$/, "Invalid characters in the mobile number")
  .required("Mobile is Required"),
      message: Yup.string().trim().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await createContact(values);
        if (res.state) {
          formik.resetForm();
          setSuccessMessage("Thanks for contacting us!");
          // Hide the success message after 3 seconds
          setTimeout(() => {
            setSuccessMessage("");
            setOpen(false);
          }, 3000);
        }
      } catch (err) {
        console.log("nope", err);
      }
    },
  });
  useEffect(() => {
    formik.resetForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <dialog open={open} className="dialog">
     
      <Div className="row padding">
      <div className="align-end-mobile">
            <button
              className="close"
              onClick={() => {
                setOpen(false)
                formik.resetForm()
              }}
        /></div>
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Get In Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
        <Div className="col-lg-6">
          <div className="align-end">
            <button
              className="close"
              onClick={() => {
                setOpen(false)
                formik.resetForm()
              }}
        /></div>
          <form onSubmit={formik.handleSubmit} className="row padding-top">
          
              <Div className="col-sm-6">
                {/* <label className="cs-primary_color">Full Name*</label> */}
                <input
                type="text"
                placeholder="Full Name*"
                  className="cs-form_field"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p style={{ color: "red" }}>{formik.errors.name}</p>
                ) : null}

                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
              <input
                placeholder="Email*"
                  type="text"
                  className="cs-form_field"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p style={{ color: "red" }}>{formik.errors.email}</p>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
              <input
                placeholder="Project Type"
                  type="text"
                  className="cs-form_field"
                  name="project"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.project}
                />
                {formik.touched.project && formik.errors.project ? (
                  <p style={{ color: "red" }}>{formik.errors.project}</p>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
              <input
                placeholder="Mobile Number*"
                  type="text"
                  className="cs-form_field"
                  name="mobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <p style={{ color: "red" }}>{formik.errors.mobile}</p>
                ) : null}
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
              <textarea
                placeholder="Message*"
                  cols="30"
                  rows="5"
                  className="cs-form_field"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}></textarea>
                {formik.touched.message && formik.errors.message ? (
                  <p style={{ color: "red" }}>{formik.errors.message}</p>
                ) : null}
                <Spacing lg="25" md="25" />
              </Div>
              <h3>{successMessage}</h3>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" type="submit">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
     
    </dialog>
  );
}
