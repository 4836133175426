import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pageTitle } from "../../helper";
import Button from "../Button";
// import Cta from '../Cta'
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export default function PortfolioDetailsPage() {
  const navigate = useNavigate();
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const Data = [
    {
      title: "Grade Hero",
      description:
        "Grade Hero is a dynamic and innovative academic assistance business, committed to easing and smoothing the college experience for every individual. The goal is to provide the highest level of customer satisfaction by offering customized services to meet the unique needs of our clients.",
      carousel1text:
        "The Grade Hero admin panel simplifies job management, enhances communication, and boosts overall productivity for writers, managers, and clients. It includes various features such as Application Management, Job Creation, Job Management, Employee Management, Calendar Scheduling, Real Time Chat and more.",
      carousel1: [
        {
          imgUrl: "/images/projects/gradeHero/main.webp",
        },
        {
          imgUrl: "/images/projects/gradeHero/1.webp",
        },
        {
          imgUrl: "/images/projects/gradeHero/2.webp",
        },
        {
          imgUrl: "/images/projects/gradeHero/3.webp",
        },
      ],
      carousel2text:
        "The mobile app offers an intuitive interface with multiple sections, including a home page displaying user levels and perks, project updates, and a Loyalty Balance page. It also features a Projects hub for comprehensive management, categorizing projects and providing full details and customer support via chat",
      carousel2: [
        {
          imgUrl: "/images/projects/gradeHero/app1.webp",
        },
        {
          imgUrl: "/images/projects/gradeHero/app2.webp",
        },
      ],
      category:
        "Frontend Backend UI/UX Design; Admin panel, Mobile App, Website",
      software: "React Native MongoDB Node Express",
    },
    {
      title: "NuWorlds",
      category: "Metaverse",
      software: "Spline, Framer",
      description:
        "This project is a dynamic and highly visual website that services as its own meta verse. The website contains plenty of pages and sections serving different purposes such as a screen time TV binging library, a creative writing land, a virtual universe builder, a gaming platform, A VR and AR alternative reality metaverse, a travel experience geo-locator and much more.",
      carousel1: [
        {
          imgUrl: "/images/projects/nuworlds/1.webp",
        },
        {
          imgUrl: "/images/projects/nuworlds/2.webp",
        },
        {
          imgUrl: "/images/projects/nuworlds/3.webp",
        },
      ],
    },
    {
      title: "Residents",
      mainImage: "residents/main.webp",
      url: "https://www.residents.ai/",
      image1: "residents/web.webp",
      text1:
        "Residents includes a website that works to introduce people to the platform, what it offers, and its uses.",
      carousel1text:
        "The admin panel of “Residents” is a dynamic control center, empowering administrators with intuitive tools for seamless community management. From event organization and member engagement to financial oversight, it offers a comprehensive suite, ensuring efficient, transparent, and vibrant community interactions. The admin panel contains an extensive list of tools and features including surveys, newsletter management, event announcements, resource management, member and staff management, inquiry management,  AI chatbot, payment management and various broadcast tools.",
      carousel1: [
        {
          imgUrl: "/images/projects/residents/admin1.webp",
        },
        {
          imgUrl: "/images/projects/residents/admin2.webp",
        },
        {
          imgUrl: "/images/projects/residents/admin3.webp",
        },
        {
          imgUrl: "/images/projects/residents/admin4.webp",
        },
        {
          imgUrl: "/images/projects/residents/admin5.webp",
        },
      ],
      description:
        "Residents is a Software as a Service (SaaS) platform designed to streamline community engagement, providing residents and administrators with tools for communication, resource management, and event coordination within an organization or city.",
      carousel2text:
        "Residents' mobile app offers a streamlined community experience, putting essential services and engagement tools at members' fingertips. Seamlessly navigate events, surveys, and resources, all while enjoying secure payment options and personalized notifications, enhancing community connectivity in the digital age.",
      carousel2: [
        {
          imgUrl: "/images/projects/residents/mobile1.webp",
        },
        {
          imgUrl: "/images/projects/residents/mobile2.webp",
        },
        {
          imgUrl: "/images/projects/residents/mobile3.webp",
        },
      ],
      carousel3text:
        "The member panel has similar features to the app with additional dashboard for analytics and usage. The members can navigate events, inquiries, surveys, check resources and chat with the AI chatbot on a member panel that is user friendly in both web and mobile.",
      carousel3: [
        {
          imgUrl: "/images/projects/residents/member1.webp",
        },
        {
          imgUrl: "/images/projects/residents/member2.webp",
        },
        {
          imgUrl: "/images/projects/residents/member3.webp",
        },
        {
          imgUrl: "/images/projects/residents/member4.webp",
        },
        {
          imgUrl: "/images/projects/residents/member5.webp",
        },
        {
          imgUrl: "/images/projects/residents/member6.webp",
        },
      ],
      category:
        "Frontend Backend UI/UX Design; Admin panel, Member Panel, Mobile App, Website ",
      software: "Next React Native MongoDB Node Express AWS",
    },
    {
      title: "Hollo AI",
      url: "https://www.hollo.ai/",
      description:
        "Hollo redefines digital presence by offering a versatile platform where users effortlessly create and train their AI twin, tailor their HOLLO Link, and seamlessly engage with fans. Hollo unlocks opportunities for revenue,  brand deals, and a unified digital identity, empowering creators, influencers, and individuals to be everywhere effortlessly.",
      text1:
        "Hollo.ai website introduces people to the platform and shows them the products and offerings to get started.",
      image1: "hollo/web.webp",
      mainImage: "hollo/main.webp",
      carousel1text:
        "Hollo app allows users to train their personalized AI twin, customize HOLLO Links, manage revenue streams, and engage with fans seamlessly, providing a comprehensive platform for effortless online presence",
      carousel1: [
        {
          imgUrl: "/images/projects/hollo/mobile1.webp",
        },
        {
          imgUrl: "/images/projects/hollo/mobile2.webp",
        },
        {
          imgUrl: "/images/projects/hollo/mobile3.webp",
        },
        {
          imgUrl: "/images/projects/hollo/mobile4.webp",
        },
        {
          imgUrl: "/images/projects/hollo/mobile5.webp",
        },
        {
          imgUrl: "/images/projects/hollo/mobile6.webp",
        },
      ],
      text2:
        "The admin panel allows users to fully control and customize their AI twin and Link pages through a web panel. ",
      image2: "hollo/admin.webp",
      category:
        "Custom Solutions Frontend Backend UI/UX Design; Admin panel, Mobile App, Website",
      software: "React Next Typescript Flutter Python Fastapi",
    },
    {
      title: "Keep it Classy Nails",
      url: "https://keepitclassynails.com/",
      description:
        "Keep it classy nails is a premium visual e-commerce website selling nail collections reflective of Monyetta Shaw- Carter, the owner. ",
      carousel1text:
        "The home page includes dynamic designs and action buttons that reflect the brand's vibe and while also being informative of what the shop is and what is offered.  Additionally, a simple 'add to cart' system with the option to add to the wishlist. The website also has an easy to use, and straightforward checkout system for everyone to enjoy. The Products page shows adult collections and kids collections neatly categorized and the about us and contact us pages are both simple and visual so it doesn't overwhelm the user. ",
      carousel1: [
        {
          imgUrl: "/images/projects/keepitclassynails/1.webp",
        },
        {
          imgUrl: "/images/projects/keepitclassynails/2.webp",
        },
        {
          imgUrl: "/images/projects/keepitclassynails/3.webp",
        },
      ],
      category: "Custom Frontend Backend  e-commerce",
      software: "Shopify",
    },
    {
      title: "Lover Los Angeles",
      url: "https://loverlosangeles.com/",
      description:
        "Lover Los Angeles is a streetwear clothing brand based in Los Angeles spreading  a movement that represents confidence in appearance. It's website reflects just that with easy product listing that allows the ability to add and fully control all products and sizings for any merch released. The website also includes an easy to use cart adding and shipping process. The Lover LA website allows the user to surf through specifically curated categories and collections that suit their specific need to avoid seeing unrelated content. Additionally, Users can see customer testimonials and real life pictures of buyers who used the merch and were satisfied which helps in building trust loyalty. ",
      carousel1: [
        {
          imgUrl: "/images/projects/lla/1.webp",
        },
        {
          imgUrl: "/images/projects/lla/2.webp",
        },
        {
          imgUrl: "/images/projects/lla/3.webp",
        },
        {
          imgUrl: "/images/projects/lla/4.webp",
        },
      ],
      category: " E-commerce Website",
      software: "Shopify",
    },
    {
      title: "Boutique Beyrouth",
      mainImage: "boutiqueBeyrouth/1.webp",
      description:
        "This premium clothing brand website offers a seamless shopping experience with its user-friendly design and easy checkout process. The back-end management panel features tools for cost management, email marketing automation, and customer inquiry management. A top-notch example of e-commerce design, this website is a must-see for those in the fashion industry.",
      carousel1text:
        "This premium clothing brand website offers a seamless shopping experience with its user-friendly design and easy checkout process. The back-end management panel features tools for cost management, email marketing automation, and customer inquiry management. A top-notch example of e-commerce design, this website is a must-see for those in the fashion industry.",
      carousel1: [
        {
          imgUrl: "/images/projects/boutiqueBeyrouth/1.webp",
        },
        {
          imgUrl: "/images/projects/boutiqueBeyrouth/2.webp",
        },
        {
          imgUrl: "/images/projects/boutiqueBeyrouth/3.webp",
        },
      ],
      carousel2text:
        "This admin panel allows the admins to control and customize their website and manage their products with utmost ease.",
      carousel2: [
        {
          imgUrl: "/images/projects/boutiqueBeyrouth/4.webp",
        },
        {
          imgUrl: "/images/projects/boutiqueBeyrouth/5.webp",
        },
        {
          imgUrl: "/images/projects/boutiqueBeyrouth/6.webp",
        },
        {
          imgUrl: "/images/projects/boutiqueBeyrouth/7.webp",
        },
      ],
      category: "Frontend Backend UI/UX Design E-commerce Website",
      software: "React.js Next.js MongoDB Node Express",
    },
    {
      title: "Cool Kids Group",
      url: "https://coolkids.group/",
      description:
        "Cool kids is an interactive and visual e-commerce website for cool kids skating wear and skateboards collections. It includes a home page which has dynamic designs and action buttons that reflect the shop's vibes and the brand's skateboarding aesthetic. Additionally, the customer can easily contact their shop and get in touch for any partnership opportunities, inquiries and more! The website also has an easy to user, and straightforward checkout system for everyone to enjoy.",
      carousel1: [
        {
          imgUrl: "/images/projects/coolkidsgroup/1.webp",
        },
        {
          imgUrl: "/images/projects/coolkidsgroup/2.webp",
        },
        {
          imgUrl: "/images/projects/coolkidsgroup/3.webp",
        },
      ],
      category: "Custom Frontend Backend  e-commerce website",
      software: "Next js, Shopify",
    },
    {
      title: "Ryaan Sayegh Portfolio",
      url: "https://ryaansayegh.com/",
      description:
        "This project is a simple website showcasing a software development portfolio. The website is all inclusive showing sections and distinctions between projects. The portfolio is visual but also simple with a neat contact us form for future projects and partnerships. ",
      carousel1: [
        {
          imgUrl: "/images/projects/ryaan/1.webp",
        },
        {
          imgUrl: "/images/projects/ryaan/2.webp",
        },
      ],
      category: "Custom Frontend Backend  website",
      software: "",
    },
    {
      title: "Jullian Portfolio",

      description:
        "This project is a highly visual customized DJ portfolio. The website is very visual and interactive, with enough content to introduce the DJ while also not taking too much space where it's overpowering or boring. The website also includes a tickets section and an upcoming shows section showcasing upcoming events. Additionally, it has a contact form that allows fans and audiences to get email notifications on upcoming events.",
      carousel1: [
        {
          imgUrl: "/images/projects/jullian/1.webp",
        },
        {
          imgUrl: "/images/projects/jullian/2.webp",
        },
      ],
      category: "Custom Frontend Backend website",
      software: "Next.js",
    },
    {
      title: "Bry Properties",
      url: "https://www.bry.properties/#/",
      description:
        "This is a customized visual website showcasing experimental properties with information for interested people.  The website shows properties in a neat and straightforward way showcasing information and avoiding overwhelming the user. The customer can easily contact their owner and get in touch for any rental opportunities, inquiries and more!",
      carousel1: [
        {
          imgUrl: "/images/projects/bryProperties/1.webp",
        },
        {
          imgUrl: "/images/projects/bryProperties/2.webp",
        },
      ],
      category: "Custom Website",
      software: "React.js",
    },
    {
      title: "Call Trans",
      description:
        "Caltrans is a transportation platform that manages and oversees projects to improve and maintain California's transportation infrastructure, ensuring safe and efficient travel for the public.",
      mainImage: "callTrans/main.webp",
      carousel1text:
        "The  admin panel serves as a centralized platform for efficiently managing transportation projects, encompassing tasks such as product management, inventory tracking, commission monitoring, and payment management",
      carousel1: [
        {
          imgUrl: "/images/projects/callTrans/admin1.webp",
        },
        {
          imgUrl: "/images/projects/callTrans/admin2.webp",
        },
        {
          imgUrl: "/images/projects/callTrans/admin3.webp",
        },
      ],
      carousel2text:
        "The app  allows users to be  assigned inspections and for admins to review said inspections and continuously come back to them. Admins can approve or reassign inspections. PDFs are automatically created once the inspection is approved and finished.",
      carousel2: [
        {
          imgUrl: "/images/projects/callTrans/app1.webp",
        },
        {
          imgUrl: "/images/projects/callTrans/app2.webp",
        },
        {
          imgUrl: "/images/projects/callTrans/app3.webp",
        },
      ],
      category: "Custom Solutions Frontend Backend and mobile app",
      software: "Nodejs Javascript Html css  php  jquery  flutter",
    },
    {
      title: "Smart City Signals",
      url: "https://user.smartcitysignals.com/",
      description:
        "Smart City is a new transportation solution developed to help government agencies better manage their traffic signal and utility assets. This software could also be used by civilians in need of fast transportation to have some leverage on traffic signals if the need arises.",
      subDescription:
        "Key features include: <ol></br><li> Synched street signals with visual UI to showcase real-time changes.</li></br><li>You can view video streamed from the Raspberry PI's that are installed in the traffic controllers. The video feed is live and uninterrupted, and can be viewed from anywhere in the world.</li></ol>",
      carousel1: [
        {
          imgUrl: "/images/projects/smartCity/1.webp",
        },
        {
          imgUrl: "/images/projects/smartCity/2.webp",
        },
        {
          imgUrl: "/images/projects/smartCity/3.webp",
        },
      ],
      category: "Custom Solutions Frontend Backend",
      software: " Html CSS Javascript PHP MySQL",
    },
    {
      title: "NTCIP",
      description:
        "This NTCIP website allows traffic management administrators to easily update and configure the timing settings of traffic controllers, helping to keep traffic flowing smoothly and efficiently on roadways. With an intuitive interface and real-time updates, this website streamlines the process of managing traffic infrastructure and helps ensure the safety of drivers and pedestrians.",
      carousel1: [
        {
          imgUrl: "/images/projects/NTCIP/1.webp",
        },
        {
          imgUrl: "/images/projects/NTCIP/2.webp",
        },
      ],
      category: "Custom Software Frontend Backend",
      software: "HTML CSS JavaScript PHP MySQL Node.js",
    },
    {
      title: "School Routes",
      url: "https://www.schoolroutes.org/",
      description:
        "SchoolRoutes is a user-friendly platform app that helps parents and students organize safe routes to school, whether on foot, bike, or car. It allows easy communication, ensures student safety, and provides guidance for the shortest and safest routes.",
      mainImage: "schoolRoutes/main.webp",
      carousel1text:
        "SchoolRoutes is a cutting-edge app that helps parents and administrators organize and plan safe and sustainable routes to school. With its user-friendly interface and powerful features, it empowers families to take control of their transportation options and make sure their children are safe at all times.",
      carousel1: [
        {
          imgUrl: "/images/projects/schoolRoutes/mobile1.webp",
        },
        {
          imgUrl: "/images/projects/schoolRoutes/mobile2.webp",
        },
      ],
      carousel2text:
        "SchoolRoutes Admin facilitates seamless updates and allows users to organize and join events like bike trains, walking school buses, and carpools, fostering a collaborative and efficient school transportation system.",
      carousel2: [
        {
          imgUrl: "/images/projects/schoolRoutes/admin1.webp",
        },
        {
          imgUrl: "/images/projects/schoolRoutes/admin2.webp",
        },
        {
          imgUrl: "/images/projects/schoolRoutes/admin3.webp",
        },
        {
          imgUrl: "/images/projects/schoolRoutes/admin4.webp",
        },
        {
          imgUrl: "/images/projects/schoolRoutes/admin5.webp",
        },
      ],
      category: "Mobile Web",
      software: "flutter Java PHP html CSS MySQL",
    },
    {
      title: "Jonah's Kabob",
      url: "https://jonahskabob.shop/",
      description:
        "Jonah's Kabob is an interactive Kabob Restaurant website for Jonah, AKA Nick Antonyan.  The website was built to reflect the fun and lively vibe of both the shop and its owner. The home page includes dynamic designs and action buttons that reflect the restaurant's vibe and livelihood while also being informative and welcoming.  The customer can easily contact their shop and get in touch for any partnership opportunities, inquiries and more!  The website also has direct connection with delivery platforms which allows users to order right away with ease.",
      carousel1: [
        {
          imgUrl: "/images/projects/jonahsKabob/1.webp",
        },
        {
          imgUrl: "/images/projects/jonahsKabob/2.webp",
        },
        {
          imgUrl: "/images/projects/jonahsKabob/3.webp",
        },
      ],
      category: "Custom Solutions Frontend Backend",
      software: "React MongoDB Node Express",
    },
    {
      title: "We Pop Bottles",
      url: "https://wepopbottles.com/",
      description:
        "We Pop Bottles is a customized interactive and friendly website for a wine tasting community. The home page includes dynamic designs and action buttons that reflect the community's vibe and livelihood while also being informative and welcoming.   It also has the standard 'about us' section, an events section showcasing upcoming meetings and events, and a 'contact us' that allows people to partner with the brand. ",
      carousel1: [
        {
          imgUrl: "/images/projects/wePopBottles/1.webp",
        },
        {
          imgUrl: "/images/projects/wePopBottles/2.webp",
        },
      ],
      category: "Custom Frontend Backend website",
      software: "HTML5 + bootstrap",
    },
    {
      title: "Operation Fitness",
      url: "https://www.miketorchia.com/",
      description:
        "Operation Fitness is an improved all inclusive fitness and concierge website. It was created to hold as much information and details in an non-overwhelming manner.",
      carousel1text:
        "The home page includes dynamic designs and action buttons that reflect the business' vibe and livelihood while also being informative and welcoming.  It includes the standard 'about us' page which shows the owner and the vision of the operation. The website also  showcases countless services that were once distributed across websites all in one place to make it easier for the customer to get information and navigate. ",
      carousel1: [
        {
          imgUrl: "/images/projects/operationFitness/1.webp",
        },
        {
          imgUrl: "/images/projects/operationFitness/2.webp",
        },
        {
          imgUrl: "/images/projects/operationFitness/3.webp",
        },
      ],
      category: "Custom Frontend Backend Website",
      software: "html css javascript Node.js MongoDB",
    },
    {
      title: "Creator Space",
      url: "https://www.creatorspace.la/",
      description:
        "The Creator Space LA is a creative visual and simple website that shows events and rentable spaces for an events venue in Los Angeles.",
      mainImage: "creatorSpace/main.webp",
      carousel1: [
        {
          imgUrl: "/images/projects/creatorSpace/1.webp",
        },
      ],
      carousel1text:
        "The home page includes dynamic designs and action buttons that reflect the venue's vibe. It is accompanied by a reflective and dynamic 'about us' page that is filled with information about the venue and its vision. ",
      carousel2: [
        {
          imgUrl: "/images/projects/creatorSpace/2.webp",
        },
      ],
      carousel2text:
        "The website includes pages showing users listings of what the venue has to offer in terms of rentable rooms with visuals accompanying the descriptions. Additionally, it has a blog post that dives more into the venue's work and concept and art creation. ",
      carousel3text:
        "The customer can easily contact the venue and get in touch for any partnership opportunities, inquiries and more!  The website also includes a page that visually shows what the venue has to offer in terms of equipment, services and amenities. ",
      carousel3: [
        {
          imgUrl: "/images/projects/creatorSpace/3.webp",
        },
      ],
      category: "Custom Solutions Frontend Website",
      software: "React.js MongoDB Node Express",
    },
    {
      title: "le Bowl",
      description:
        "LeBowl restaurant on Melrose Ave, Los Angeles is famous for its unique and delicious cuisine, and now their online presence is just as captivating. The interactive landing page developed by me showcases the essence of LeBowl and invites visitors on a culinary journey. From scrolling through mouth-watering dishes to exploring the vibrant atmosphere of the restaurant, this landing page is a feast for the senses. Come discover the world of LeBowl and satisfy your cravings.",
      carousel1: [
        {
          imgUrl: "/images/projects/leBowl/1.webp",
        },
        {
          imgUrl: "/images/projects/leBowl/2.webp",
        },
      ],
      category: " Custom Solutions Frontend Backend",
      software: "Html CSS Javascript",
    },
    {
      title: "Techademics",
      description:
        "Created a platform for a spiritual university to reach students and help them grow in their careers and lives. The website is built on Wix and is fully customizable and easy to use.",
      carousel1: [
        {
          imgUrl: "/images/projects/techadamics/main.webp",
        },
        {
          imgUrl: "/images/projects/techadamics/1.webp",
        },
        {
          imgUrl: "/images/projects/techadamics/2.webp",
        },
      ],
      category: "Frontend UI/UX Design",
      software: "Wix",
    },
    {
      title: "Chinese Union",
      description:
        "The Chinese Union platform integrates admin, student, and merchant apps to foster efficient communication, streamlined operations, and enhanced services within a university community.",
      carousel1text:
        "The admin panel streamlines control and oversight for Chinese Union, encompassing features like Member Support, Newsletter, Events, Shop, Guide, AI Chatbot, Membership, and Merchant Management. ",
      carousel1: [
        {
          imgUrl: "/images/projects/chineseUnion/admin1.webp",
        },
        {
          imgUrl: "/images/projects/chineseUnion/admin2.webp",
        },
        {
          imgUrl: "/images/projects/chineseUnion/admin3.webp",
        },
        {
          imgUrl: "/images/projects/chineseUnion/admin4.webp",
        },
      ],
      mainImage: "chineseUnion/main.webp",
      carousel2text:
        "The merchant app empowers businesses with tools for discount management, QR code scanning, and comprehensive profile control, enhancing engagement with Chinese Union members.",
      carousel2: [
        {
          imgUrl: "/images/projects/chineseUnion/merchant1.webp",
        },
        {
          imgUrl: "/images/projects/chineseUnion/merchant2.webp",
        },
      ],
      carousel3text:
        "The student app streamlines interactions, providing Chinese Union members with access to exclusive discounts, event information, and AI-powered support.",
      carousel3: [
        {
          imgUrl: "/images/projects/chineseUnion/app1.webp",
        },
        {
          imgUrl: "/images/projects/chineseUnion/app2.webp",
        },
      ],
      category: "Frontend Backend UI/UX Design; Admin panels, Different apps.",
      software: "Next React Native MongoDB Node Express AWS",
    },
  ];
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  let nextId = parseInt(id, 10) === 21 ? 0 : parseInt(id, 10) + 1;
  let prevId = parseInt(id, 10) === 0 ? 21 : parseInt(id, 10) - 1;
  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      prevId = parseInt(id, 10) === 0 ? 21 : parseInt(id, 10) - 1;
      navigate(`/projects/project-details?id=${prevId}`);
    } else if (event.key === "ArrowRight") {
      nextId = parseInt(id, 10) === 21 ? 0 : parseInt(id, 10) + 1;
      navigate(`/projects/project-details?id=${nextId}`);
    }
  };
  pageTitle("Project Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); //
  return (
    <>
      {/* <PageHeading 
        title='Portfolio Details'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.portfolioDetailsId}
      /> */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading title={Data[id].title || ""} subtitle="">
              <Spacing lg="40" md="20" />
              <p>{Data[id].description}</p>
              <Spacing lg="10" md="10" />
              {Data[id].subDescription && (
                <p
                  dangerouslySetInnerHTML={{ __html: Data[id].subDescription }}
                ></p>
              )}
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="30" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Category:
                </h3>
                {Data[id].category && (
                  <p className="cs-m0">{Data[id].category}</p>
                )}
                <Spacing lg="30" md="30" />
              </Div>

              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Software:
                </h3>
                {Data[id].software && (
                  <p className="cs-m0">{Data[id].software}</p>
                )}
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
            {Data[id].url && (
              <div>
                <a
                  href={Data[id].url}
                  style={{ textDecoration: "underline", color: "#02A9F7" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check the website
                </a>
              </div>
            )}
          </Div>
        </Div>
        <Spacing lg="90" md="40" />
        <Div
          style={{ display: "flex", justifyContent: "center" }}
          className="project-details-images"
        >
          {Data[id].mainImage && (
            <img
              src={`/images/projects/${Data[id].mainImage}`}
              alt="Details"
              className="cs-radius_15 text-center"
            />
          )}
        </Div>
        {/*part one started*/}
        {Data[id].text1 && (
          <>
            <Spacing lg="40" md="20" />
            <p className="text-center">{Data[id].text1}</p>
            <Spacing lg="40" md="20" />
            <Div
              style={{ display: "flex", justifyContent: "center" }}
              className="project-details-images"
            >
              <img
                src={`/images/projects/${Data[id].image1}`}
                alt="Details"
                className="cs-radius_15 "
              />
            </Div>
            <Spacing lg="40" md="20" />
          </>
        )}
        {/*part one ended*/}

        {/*part two started*/}
        {Data[id].text2 && (
          <>
            <Spacing lg="40" md="20" />
            <p className="text-center">{Data[id].text2}</p>
            <Spacing lg="40" md="20" />
            <Div
              style={{ display: "flex", justifyContent: "center" }}
              className="project-details-images"
            >
              <img
                src={`/images/projects/${Data[id].image2}`}
                alt="Details"
                className="cs-radius_15 "
              />
            </Div>
            <Spacing lg="40" md="20" />
          </>
        )}
        {/*part two ended*/}

        {/*part three started*/}
        {Data[id].carousel1text && (
          <>
            <Spacing lg="40" md="20" />
            <p className="text-center ">{Data[id].carousel1text}</p>
          </>
        )}
        {Data[id].carousel1 && (
          <>
            {" "}
            <Spacing lg="40" md="20" />
            <Slider {...settings} className="cs-gap-24">
              {Data[id].carousel1.map((item, index) => (
                <Div key={index}>
                  <img
                    alt="Details"
                    src={item.imgUrl}
                    className="cs-radius_15 "
                    style={{ marginBottom: "15px" }}
                  />
                </Div>
              ))}
            </Slider>
            <Spacing lg="40" md="20" />
          </>
        )}
        {/*part three ended*/}

        {/*part four started*/}
        {Data[id].carousel2text && (
          <>
            <Spacing lg="40" md="20" />
            <p className="text-center">{Data[id].carousel2text}</p>
            <Spacing lg="40" md="20" />
            <Slider {...settings} className="cs-gap-24">
              {Data[id].carousel2 &&
                Data[id].carousel2.map((item, index) => (
                  <Div key={index}>
                    <img
                      alt="Details"
                      src={item.imgUrl}
                      className="cs-radius_15 "
                      style={{ marginBottom: "15px" }}
                    />
                  </Div>
                ))}
            </Slider>
            <Spacing lg="40" md="20" />
          </>
        )}
        {/*part four ended*/}

        {/*part five started*/}
        {Data[id].carousel3text && (
          <>
            <Spacing lg="40" md="20" />
            <p className="text-center">{Data[id].carousel3text}</p>
            <Spacing lg="40" md="20" />
            <Slider {...settings} className="cs-gap-24">
              {Data[id].carousel3 &&
                Data[id].carousel3.map((item, index) => (
                  <Div key={index}>
                    <img
                      alt="Details"
                      src={item.imgUrl}
                      className="cs-radius_15 "
                      style={{ marginBottom: "15px" }}
                    />
                  </Div>
                ))}
            </Slider>
            <Spacing lg="40" md="20" />
          </>
        )}
        {/*part five ended*/}

        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink={`/projects/project-details?id=${prevId}`}
              btnText="Prev Project"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button
              btnLink={`/projects/project-details?id=${nextId}`}
              btnText="Next Project"
              onClick
            />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      {/* <Cta 
        title='info@skew.digital' 
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      /> */}
    </>
  );
}
