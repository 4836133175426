import React from 'react';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
// import Button from '../Button';
import PortfolioSlider from '../Slider/PortfolioSlider';
import VerticalLinks from '../VerticalLinks';
import { useLocation } from 'react-router-dom';
function DigitalMarketingProjects() {
  const location = useLocation();

  const heroSocialLinks = [
    {
      name: 'Services',
      links: '/service',
      class:location.pathname === '/service' ? 'Active' : ''
    },
    {
      name:'|'
    },
    {
      name: 'Projects',
      links: '/digital-marketing-projects',
      class:location.pathname === '/digital-marketing-projects' ? 'Active' : '',
    },
  ];

    return (
    <>
        
        <Spacing lg='150' md='40'/>
      <Spacing lg='20' md='50'/>
    
      <Div className='d-flex container flex-column mt-4 align-items-start align-self-center'>
      <VerticalLinks
          data={heroSocialLinks}
          className='cs-shape_4'
          variant="cs-left_side"
        />
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>

        <Div className="container">
       
          <SectionHeading
            title="Projects to Explore"
            subtitle=""
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
        <Div style={{ display: "flex", justifyContent: "center",marginTop:"50px" }} >
          {/* <Button btnLink={"/projects"} btnText={"See all Projects"}  /> */}
          </Div>
      </Div>
  
      

     {}
    </>
    );
}

export default DigitalMarketingProjects;
