import React from "react";
import "./hero.scss";
import Button from "../Button";
import Div from "../Div";
import VerticalLinks from "../VerticalLinks";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
  setOpen,
  open,
}) {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const className = location.pathname === "/service" ? "serviceActive" : location.pathname === "/digital-marketing-projects" ? "projectActive" : "";
  console.log('Applied class:', className);

  
  return (
    <Div
      className={`cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1 open-dialog`}
    >
      <img
        className="blue-ball"
        src="/images/home/Ellipse 8.webp"
        alt="blue ball"
      />
      <div style={{ overflow: "hidden" }}>
      
        <img
          className="object-2"
          src="/images/home/Group 2.webp"
          alt="blue ball"
        />
      </div>
      <img
        className="object-1"
        src="/images/home/Group 3.webp"
        alt="blue ball"
      />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text ">
          <h1 className="cs-hero_title slideIn ">
            Transforming<span> Visions </span>
            <br />
            Into <span>Realities</span>
          </h1>
          <p className="home-subtitle ">Award winning AI focused Agency</p>
         
          <Div className="cs-hero_info">
            <Div>
              {btnLink && btnText && (
                <Button btnLink={btnLink} btnText={btnText} />
              )}
            </Div>
            <Div>
              <Div className="cs-hero_subtitle">{subtitle}</Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <VerticalLinks  
      className={className} 
      data={heroSocialLinks} 
      title={socialLinksHeading} 
    />
      <div className="helper" onClick={() => setOpen(!open)}>
        Contact Us
      </div>
      <div onClick={() => setOpen(!open)} className="cs-down_btn">
        .
      </div>
    </Div>
  );
}
